#leaderboard {
  width: 100%;
  height: 100%;
  background-color: #424344;
  color: white;
  text-align: center;
  flex-direction: column;
}

.leaderboard {
  &__header {
    width: 100%;
    height: auto;
    padding: 10px;
    border-bottom: solid 2px gray;
    margin-bottom: 20px;
    background-color: rgb(43, 43, 43);
    overflow: hidden;

    &--buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap-reverse;
    }
  }
  &__button {
    &--invite {
      background-color: rgb(94, 94, 94);
      border-radius: 5px;
      color: white;
      height: 1.875rem;
      overflow: hidden;
    }
    &--help {
      border-radius: 5px;
      height: 1.875rem;
    }
    &--leave {
      background-color: #ce1212;
      border-radius: 5px;
      color: white;
      height: 1.875rem;
      overflow: hidden;
    }
    &--settings {
      margin: .625em;
      border-radius: 5px;
      width: 30vw;
      height: 2em;
      background-image: url("/settings.svg");
      background-repeat: no-repeat;

    }
  }

  &__info {
    &--unflipped {
      font-size: 0.5em;
    }
  }

  &__players {
    &--header {
      margin: 0;
    }
    &--words {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      overflow-y: auto;
      align-content: flex-start;
      justify-content: space-around;
      background-color: rgb(94, 94, 94);
      border: solid 2px gray;
      border-radius: 10px;
      padding: 10px;
    }
    &--container {
      height: 65vh;
    }
  }
}

$colors: #9e080840, #80089e40, #081b9e40, #089e9040, #0b9e0840, #9e990840, #9e4d0840;

.player {
  margin-bottom: 0.2em;
  height: fit-content;
  border-radius: 10px;
  box-shadow: 0px 1px 2px 0px rgba(61, 61, 61, 0.5);

  &__header {
    color: white;
    &[disabled] {
      color: rgb(154, 153, 153);
    }
    &--name {
      font-size: 20px;
      font-weight: bold;
      max-width: 40vw;
      overflow: hidden;
      margin: auto;
    }
    &--score {
      font-style: italic;
      font-size: 0.8em;
      margin: 0 1em 0 1em;
    }
  }
  &__words {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 10px;
  }
  &--current {
    border: solid 2px white;
  }
}

@for $i from 1 through length($colors){
  .player:nth-child(#{length($colors)}n+#{$i}) {
    
    background: nth($colors, $i);
  }
}

@media screen and (max-width: 500px) {
  #leaderboard {
    height: 75vh;
    min-height: 150px;
  }

  .leaderboard {
    &__header {
      h2 {
        font-size: 1.5rem;
      }
    }
    &__players {
      height: 50vh;
      &--words{
        height: 50vh;
      }
    }
  }
}