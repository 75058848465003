#play-menu {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  padding: 1.25rem 1.875rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.313rem;
  text-align: center;
  box-shadow: 0px 0px 9px 0px;
}

.play-menu {
  &__header {
    &--count {
      font-size: .8rem;
      margin-top: -.8em;
      color: rgb(63, 63, 63);
      margin-bottom: 1em;
    }
  }
  &__checkbox {
    &--label {
      font-size: 0.5rem;
      margin-right: 0.5em;
    }
  }
}

.menu-button {
  margin-top: 0.625rem;
}

.button--link {
  &:not(:first-child) {
    margin-left: 0.313rem;
  }
}


.hidden {
  visibility: hidden;
  display: none;
}

.title {
  color: rgb(67, 67, 67);
}

.radio-form {
  display: flex;
}

.inline-label {
  margin-left: 0.125rem;
  margin-right: 0.625rem;
}

.modal {
  padding: 1.25rem 1.875rem;
  background-color: white;
  align-items: center;
  border-radius: 5px;
  text-align: center;
}