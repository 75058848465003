.tile {
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 0.5rem;
  margin: 0.125rem;
  text-align: center;
  transition: transform 0.2s;
  transform-style: preserve-3d;
  color: black;
}

.tile__letter {
  font-size: 2.5em;
  line-height: 3.125rem;
}

.tile--unflipped {
  transform: rotateY(180deg);
}

.tile__face {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  background: linear-gradient(to top, darkgray, 1%, rgb(253,235,185));
  backface-visibility: hidden;
  user-select: none;
  box-shadow: 2px 2px #c1b38a;
}

.tile__face--front {
  backface-visibility: hidden;
}

.tile__face--back {
  backface-visibility: hidden;
  animation-duration: 0.3s;
  transform: rotateY( 180deg );
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(1deg); }
  50% { transform: translate(1px, 1px) rotate(-1deg); }
  100% { transform: translate(0px, 0px) rotate(1deg); }
}