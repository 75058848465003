#room {
  width: 100vw;
  height: 100vh;
  background-color: rgb(24, 24, 24);
  overflow: hidden;
  overscroll-behavior: none;
  display: flex;
}

.overlay {
  &__paused {
    z-index: 1000;
    position: absolute;
    width: 100vw;
    height: 100vh;
    border: solid 10px red;
  }
}

.page {
  &__button {
    &--home {
      position: fixed;
      top: 1%;
      left: 1%;
      border-radius: 5px;
      padding: 0;
      background-color: #5c5c5c;
      width: 30px;
      height: 30px;
    }
  }
}

.panel {
  &--left {
    position: relative;
    left: 0%;
    width: 50vw;
  }
  &--right {
    position: relative;
    right: 0%;
    width: 50vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    border-left: solid 0.313em black;
    border-right: solid 0.313em black;
  }
}


#letters--container {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  overflow: auto;
  width: 90%;
  height: 100%;
  max-width: 600px;
  padding-top: 15px;
  margin: auto;
}

#letters--container::-webkit-scrollbar {
  display: none;
}

.loading {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 30vw;
}

.loading h3 {
  width: 100%;
  font-size: 2vw;
  text-align: center;
  position: fixed;
  bottom: 17%;
}

.loader {
  width: 30vw;
  height: auto;
}

@media screen and (max-width: 500px) {
  #room {
    overflow: hidden;
  }
  .panel {
    &--right {
      width: 50vw;
      height: 75vh;
    }
    &--left {
      width: 50vw;
      height: 75vh;
    }
  }
}