#chat {
  height: 1vh;
  max-width: 37.5rem;
  background-color: white;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  flex: 1;
}

#chat ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  overflow-x: wrap;
  height: 100%;
  margin: 0px;
  padding: 0.313rem;
  list-style-type: none;
}

label {
  margin: 0px;
  padding-bottom: 0px;
}

.message {
  width: 95%;
  margin: 0.5rem auto 0.188rem auto;
  overflow-wrap: anywhere;
}

.message__bubble {
  padding: 0.313rem 0.625rem 0.313rem 0.625rem;
  border-radius: 10px;
  background-color: rgb(161, 217, 255);
}

.message__bubble--player-current {
  float: right;
}

.message__bubble--server-info {
  background-color: rgb(255, 161, 255);
}

.message__bubble--server-error {
  background-color: rgb(255, 161, 161);
}

.chat {
  &__input {
    margin-bottom: auto;
    &--message {
      height: auto;
    }
  }
}

@media screen and (max-width: 31.25rem) {
  #chat {
    border-radius: 0px;
  }
  #chat ul{
  }
}


