.word {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0.5em;
  &__letter {
    width: 1.2em;
    height: 1.2em;
    line-height: 1em;
    font-size: 1.2rem;
    border: solid 1px;
    background: linear-gradient(to top, darkgray, 1%, rgb(253,235,185));
    color: black;
    border-radius: 0.25em;
  }

  &:hover {
    opacity: 0.6;
  }
}