#control-panel {
  position: relative;
  max-width: 37.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  &__buttons {
    margin: 0.625rem 0 0.625rem 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  &__container {
    height: 40vh;
    width: 100%;
    max-width: 37.5rem;
    margin: auto;
  }
}

.control-panel__buttons {
  height: 10vh;
}

.control__button {
  flex-basis: 100%;
  height: 3.125rem;
  border-radius: 0.5rem;
  border: solid .625rem #fbfbfb47;
  color: white;
  &:active {
    transition: all .1s ease-in-out;
    transform: scale(0.95);
  }
  &--flip {
    background-color: rgb(34, 135, 202);
    &:hover {
      background-color: rgb(30, 113, 168);
    } 
    &:active {
      background-color: rgb(24, 91, 136);
    }
  }
  &--create {
    background-color: rgb(53, 145, 41);
    &:hover {
      background-color: rgb(42, 116, 32);
    } 
    &:active {
      background-color: rgb(33, 90, 25);
    }
  }
  &--finished {
      background-color: rgb(231, 44, 44);
      &:hover {
        background-color: rgb(168, 31, 31);
      } 
      &:active {
        background-color: rgb(129, 21, 21);
      }
  }
  &--waiting {
    background-color: rgb(211, 87, 87);
  }
}

.control__button:not(:first-child){
  margin-left: 0.625rem;
}

.control__button:focus {
  outline: none;
}

@media screen and (max-width: 31.25rem) {
  #control-panel__container {
    position: fixed;
    width: 100vw;
    height: 25vh;
    bottom: 0%;
    left: 0%;
    margin: auto;
  }
}